import React from "react"
import styles from "./ServiceBox.module.scss"
import Fade from "react-reveal/Fade"
import PropTypes from 'prop-types';

const ServiceBox = ({ name, description, icon }) => {
  const Icon = icon.icon;
  return (
    <div className={styles.box}>
      <div className={styles.iconContainer}>
        <div className={`${styles.icon} ${styles[icon.style]}`}>
          <Icon />
        </div>
      </div>
      <Fade bottom duration={700}>
        <h3>{name}</h3>
      </Fade>
      <Fade bottom duration={700}>
        <p className={styles.text}>{description}</p>
      </Fade>
    </div>
  )
}

export default ServiceBox

ServiceBox.propTypes = {
  description: PropTypes.string,
  icon: PropTypes.object,
  name: PropTypes.string,
}