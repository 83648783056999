import React from "react"
import Layout from "../components/layout/Layout"
import ServicesHP from "../components/services/ServicesHP"
import WorkSection from "../components/sections/WorkSection"
import Header from "../components/sections/Header"
import HeaderText from "../components/HeaderText"
import SEO from "../components/SEO"
import Benefits from "../components/sections/Benefits"
import Cta from "../components/Cta"
import KovoartAbout from "../components/sections/KovoartAbout"

export default () => {
  return (
    <Layout page="homepage">
      <SEO title="Umělecké kovářství a zámečnictví" />
      <Header>
        <HeaderText page="index" />
      </Header>
      <ServicesHP />
      <WorkSection homepage />
      <KovoartAbout />
      <Cta />
      <Benefits />
    </Layout>
  )
}
