import React from 'react'

export const Anvil = () => {

  return (
    <svg viewBox="0 0 461.7 318.9">
      <path d="M460.8,53.4c-3-6.7-11.4-7.2-22.1-7.2h-143V27.8c0-15.4-12.5-27.8-27.8-27.8H27.8v0C12.5,0,0,12.5,0,27.8v78.2
        c0.1,8.2,3.4,11.8,11.3,12.5c51.7,4.9,84.5,35.3,89.1,61.4c1.7,9.5-0.2,18.3-5.3,25.9c-0.2,0.3-0.4,0.6-0.6,0.9
        c-4.8,6.8-12.2,12.7-22,17.3l-0.5,0.2c-6.2,2.9-9.7,6.5-9.6,16.8l0,15.6h-4.5c-17.2,0-31.1,14-31.1,31.2v18.5
        c0,6.9,5.6,12.5,12.5,12.5h299c6.9,0,12.5-5.6,12.5-12.5v-18.5c0-8.3-3.3-16.1-9.2-22.1c-5.9-5.9-13.7-9.1-21.9-9.1c0,0,0,0-0.1,0
        l-4.4,0v-15.3c0-4.6,0-17-12.9-23.3c-5.6-2.8-11-7.1-15.7-12c-0.3-0.4-0.7-0.8-1.1-1.2c-9.1-10-15.1-22.4-13.8-31
        c0.4-2.5,1.5-10.1,17.8-12.7c0.1,0,0.2,0,0.3,0c41.6-6.8,123.2-41.5,162.1-86.7C458.5,66.6,463.9,60.3,460.8,53.4z M15,27.8
        C15,20.8,20.7,15,27.8,15h240.1c7.1,0,12.8,5.8,12.8,12.8v18.4h-74.3h-25.7h-36.1h-39.9H15V27.8z M319.5,271.8L319.5,271.8
        c4.3,0,8.3,1.7,11.3,4.7c3.1,3.1,4.8,7.1,4.8,11.4v16H41.7v-16c0-8.9,7.2-16.2,16.1-16.2h94.4v0h56.6h24.7H319.5z M277.3,218.1
        c5.6,5.6,11.9,10.3,18.2,13.4c3.5,1.8,4.6,4,4.6,9.9v15.3l-66.6,0v0h-61h-20.3H77.3v-15.6c0-1.4,0.1-2.2,0.1-2.7
        c0.3-0.1,0.5-0.3,0.8-0.4l0.6-0.3v0c11-5.2,19.7-11.9,25.9-19.6H277.3z M440.2,64.7c-34.3,39.8-103.8,69.9-142.3,79.4c0,0,0,0,0,0
        c-0.5,0.1-1.3,0.3-2.3,0.5c-7.5,1.7-10.5,1.9-14.5,2.9c-0.2,0-0.4,0.1-0.6,0.1v0.1c-2.8,0.8-5.3,1.7-7.5,2.7
        c-0.2,0.1-0.5,0.2-0.7,0.4c-11.6,5.8-14.9,15-15.8,20.8c-1.5,9.9,2.1,21.2,8.6,31.5h-152c2.8-8,3.5-16.6,1.9-25.7h0
        c-5.6-31.9-41.8-67.1-100.1-73.5l0-42.7h89.7h39.9h36.1h25.7h63.2h11.1h15h2.1h140.9c1.8,0,3.3,0,4.5,0.1
        C442.1,62.6,441,63.8,440.2,64.7z"/>
    </svg>
  )
}

export const Gate = () => {

  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 88.2 93.9" styles="enable-background:new 0 0 88.2 93.9;" xmlSpace="preserve">
    <path d="M88.3,21.5L88.3,21.5h-3.9v-5.1c0-1.4-1.1-2.5-2.5-2.5c-1.4,0-2.5,1.1-2.5,2.5v4.7h0v0.4h-7.8v0.2h0v-9.4
      c0-1.4-1.1-2.5-2.5-2.5c-1.4,0-2.5,1.1-2.5,2.5v8.1c-2.6-1-5.2-1.9-7.8-2.6V13V7.6c0-1.4-1.1-2.5-2.5-2.5c-1.4,0-2.5,1.1-2.5,2.5v9
      c-2.4-0.5-4.7-0.8-7-1v-5.4V2.5c0-1.4-1.1-2.5-2.5-2.5c-1.4,0-2.5,1.1-2.5,2.5v12.9h-0.1c-2.4,0-4.7,0.2-6.9,0.6v-5.2V7.6
      C34.6,6.2,33.4,5,32,5c-1.4,0-2.5,1.1-2.5,2.5v3.3v6.2c-2.7,0.7-5.3,1.7-7.8,2.8v-7.6c0-1.4-1.1-2.5-2.5-2.5c-1.4,0-2.5,1.1-2.5,2.5
      v9.2H8.9v-0.4h0v-4.7c0-1.4-1.1-2.5-2.5-2.5c-1.4,0-2.5,1.1-2.5,2.5v5.1H0v5v0h3.8v62.2H0v5.1h88.2v-5.1h-3.8V26.6h3.8v0h0.1V21.5z
      M16.6,37.6v1.1v50.1H8.9V38.7v-1.1v-11h7.8V37.6z M46.9,58.7h-5.6l1.6-4.2c-1-0.5-1.7-1.5-1.7-2.6c0-1.6,1.3-2.9,2.9-2.9
      c1.6,0,2.9,1.3,2.9,2.9c0,1.1-0.7,2.1-1.6,2.6L46.9,58.7z M41.5,20.7v18v1.7v5.3h-7v-6.9c0,0,0,0,0,0V21.4
      C36.3,21.1,38.7,20.8,41.5,20.7z M29.5,88.8h-7.8V38.9c0-4.3-0.1-8.7-0.1-13c1.9-1.1,4.6-2.3,7.9-3.3v16.3v1.2V88.8z M41.5,88.8h-7
      v-27h7V88.8z M53.6,88.8h-7v-27h7V88.8z M53.6,45.8h-7v-7c0,0,0,0,0,0V20.9c2.7,0.2,4.9,0.6,7,1.1v16.7v1.4V45.8z M66.4,88.8h-7.8
      V38.7c0,0,0,0,0,0V23.3c3.1,0.9,5.7,1.9,7.7,2.7c0,3.5,0,7.1,0,10.6c0,0,0,0,0,0V88.8z M79.3,37.6V42v46.8h-7.8V42v-4.5v-11h7.8
      V37.6z"/>
    </svg>
  )
}

export const Badge = () => {

  return (
    <svg enableBackground="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m337.903 358.822 23.413-37.992 42.252-14.41 4.681-44.377 31.789-31.295-15.114-42.006 15.114-42.007-31.788-31.295-4.681-44.376-42.252-14.41-23.413-37.993-44.285 5.347-37.619-24.008-37.619 24.01-44.284-5.347-23.413 37.992-42.252 14.41-4.681 44.376-31.789 31.295 15.114 42.007-15.114 42.007 31.788 31.294 4.681 44.377 42.252 14.41 23.413 37.992 44.285-5.346 37.619 24.009 37.619-24.009zm-211.332-170.079c0-71.367 58.062-129.429 129.429-129.429s129.429 58.062 129.429 129.429-58.062 129.429-129.429 129.429-129.429-58.062-129.429-129.429z"/><path d="m256 89.333c-54.815 0-99.41 44.595-99.41 99.41s44.595 99.41 99.41 99.41 99.41-44.595 99.41-99.41-44.595-99.41-99.41-99.41z"/><path d="m211.299 384.568-52.68 6.36-27.833-45.167-9.462-3.226-40.813 127.566 73.475-4.041 57.485 45.94 33.828-105.732z"/><path d="m381.214 345.762-27.834 45.166-52.679-6.36-34 21.7 33.828 105.732 57.485-45.94 73.475 4.041-40.813-127.566z"/></svg>
  )
}