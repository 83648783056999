import React, { useState, useEffect } from "react"
import styles from "./Header.module.scss"
import ArrowDown from "../icons/ArrowDown"
import useShowOnScroll from "../../hooks/useShowOnScroll"
import useDeviceDetect from "../../hooks/useDeviceDetect"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { useTransition, animated, config } from "react-spring"
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from "gatsby"

const getImages = graphql`
  query {
    allContentfulHlavniObrazkyNaHomepage {
      edges {
        node {
          mainImages: mainImages {
            fluid(maxWidth: 1400, quality: 50) {
              src
            }
          }
          mainImagesMob: mainImages {
            fluid(maxWidth: 800, quality: 50) {
              src
            }
          }
        }
      }
    }
  }
`

const Header = ({ children }) => {
  const isMobile = useDeviceDetect();
  const [index, set] = useState(0);
  const isScrolled = useShowOnScroll();

  const data = useStaticQuery(getImages).allContentfulHlavniObrazkyNaHomepage.edges[0].node;

  const images = data.mainImages;
  const imagesMob = data.mainImagesMob;

  let slides = [];

  isMobile ? 
    imagesMob.forEach((image, index) => {
      slides.push({ id: index, url: image.fluid.src })
    }) :
    images.forEach((image, index) => {
      slides.push({ id: index, url: image.fluid.src })
    })

  const transitions = useTransition(slides[index], item => item.id, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: config.molasses,
  })

  useEffect(() => {
    void setInterval(() => set(state => (state + 1) % images.length), 3000)    
  }, [])

  return (
    <header
      className={`${styles.headerMainMobile} ${
        !isMobile && styles.headerMainDesktop
      }`}
    >
      {transitions.map(({ item, props, key }) => (
        <animated.div
          key={key}
          className={styles.headerImages}
          style={{ ...props, backgroundImage: `url(${item.url})` }}
        />
      ))}
      <div className={styles.overlay} />
      <div className={`${styles.textWrapper} container`}>
        {children}
        <AnchorLink
          to="/#sluzby"
          title="Posunout na služby"
          className={`${styles.icon} ${isScrolled ? "" : styles.show}`}
        >
          <ArrowDown />
        </AnchorLink>
      </div>
    </header>
  )
}

export default Header

Header.propTypes = {
  children: PropTypes.node,
}