import React from 'react'
import styles from "./ArrowDown.module.scss"
import {BsChevronDown} from "react-icons/bs"

const ArrowDown = () => {
  return (
    <div className={styles.button}>
        <BsChevronDown className={styles.icon}/>
    </div>
  )
}

export default ArrowDown
