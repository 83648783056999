import React from "react"
import styles from "./HeaderText.module.scss"
import { FaQuoteRight } from "react-icons/fa"

const HeaderText = () => {
  return (
    <>
      <h1 className={styles.description}>
        Zabýváme se <span className={styles.bold}>uměleckým kovářstvím, zámečnictvím, restaurováním</span>
      </h1>
      <div className={styles.quote}>
        <FaQuoteRight className={styles.icon} />
        <blockquote className={styles.quoteText}>
          Železo, ve kterém se skrýva krása.
        </blockquote>
      </div>
    </>
  )
}

export default HeaderText
