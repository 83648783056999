import React from "react"
import ServiceBox from "./ServiceBox"
import styles from "./ServicesHP.module.scss"
import Button from "../Button"
import { graphql, useStaticQuery, Link } from "gatsby"
import { Anvil, Gate } from "../icons/CustomIcons"
import { BsTools } from "react-icons/bs"
import Fade from "react-reveal/Fade"

const getServices = graphql`
  query {
    allContentfulSluzba(limit: 3, sort: { fields: createdAt, order: ASC }) {
      edges {
        node {
          createdAt(formatString: "LLL")
          serviceName
          serviceNameBold
          id
          serviceDesc {
            serviceDesc
          }
          slug
        }
      }
    }
  }
`
const Services = () => {
  const services = useStaticQuery(getServices).allContentfulSluzba.edges

  return (
    <section id="sluzby" className={styles.section}>
      <div className="container">
        <Fade bottom duration={700}>
          <h2>
            Nabízené <span className="heading-bold">služby</span>
          </h2>
        </Fade>
        <div className={`container ${styles.services}`}>
          {services.map((service, index) => {
            let icon
            index === 0
              ? (icon = { icon: Anvil, style: "anvil" })
              : index === 1
              ? (icon = { icon: Gate, style: "gate" })
              : (icon = { icon: BsTools, style: "tools" })
            return (
              <Link key={service.node.id} to={`/sluzby/${service.node.slug}`}>
                <ServiceBox
                  name={`${service.node.serviceName ? service.node.serviceName : ""} ${service.node.serviceNameBold}`}
                  description={service.node.serviceDesc.serviceDesc}
                  icon={icon}
                />
              </Link>
            )
          })}
        </div>
        <Fade bottom duration={700}>
          <Button link="/sluzby" text="Zobrazit více" />
        </Fade>
      </div>
    </section>
  )
}

export default Services
